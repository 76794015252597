import { Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../common/buttons/primary-button';
import SelectOne from '../../../common/fields/select';
import TextField from '../../../common/fields/text-field';
import { Title, Form } from './style';
import ContactAPI from '../../../../api/contact';
import { deTranslations } from '../../../../translations';
import { useState } from 'react';

const GOOGLE_MAPS_LINK = 'https://www.google.com/maps/place/W%C3%A4hringer+Str.+39%2F5,+1090+Wien,+Austria';
const GOOGLE_MAPS_STATIC_LINK = `https://maps.googleapis.com/maps/api/staticmap?center=W%C3%A4hringer+Str.+39%2F5,+1090+Wien,+Austria&zoom=17&size=359x510&maptype=roadmap&markers=color:red%7C48.2206043,16.3552456&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

const types = [
    {
        id: 'webinare_vermarkten',
        value: 'Webinare vermarkten',
    },
    {
        id: 'content_platzieren',
        value: 'Content platzieren',
    },
    {
        id: 'sonstiges',
        value: 'Sonstiges',
    },
];

const validationSchema = Yup.object({
    type: Yup.string()
        .oneOf(types.map(type => type.id), `Thema ${deTranslations.must_not_empty}`)
        .required(`Thema ${deTranslations.must_not_empty}`),
    email: Yup.string()
        .email(deTranslations.email_invalid_warning)
        .required(`${deTranslations.email} ${deTranslations.must_not_empty}`),
    title: Yup.string().required(`Titel ${deTranslations.must_not_empty}`),
    body: Yup.string().required(`Nachricht ${deTranslations.must_not_empty}`),
});

const FormWithMapWidget = () => {
    const [message, setMessage] = useState('');
    const formik = useFormik({
        initialValues: {
            type: 0,
            email: '',
            title: '',
            body: '',
        },
        validationSchema,
        onSubmit: (
            async (values, { setSubmitting }) => {
                const [isError, isSent] = await ContactAPI.sendContactForm(values);

                if (isSent) {
                    setMessage('Nachricht erfolgreich gesendet');
                }

                if (isError) {
                    setMessage('Fehler beim Senden der Nachricht');
                }

                setSubmitting(false);
            }),
    });

    return (
        <Box display="flex" flexDirection={{xs: 'column', md: 'row'}} width={{xs: '100%', md: '718px'}}
             borderRadius="10px" overflow="hidden"
             boxShadow="0px 3px 27px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.02)">
            <Box width={{xs: '100%', md: '50%'}} boxSizing="border-box" p="22px" sx={{backgroundColor: '#FFF'}}>
                <Title>Formular ausfüllen</Title>
                <Form onSubmit={formik.handleSubmit}>
                    <SelectOne
                        fullWidth={true}
                        id="type"
                        name="type"
                        label="THEMA Wählen"
                        value={formik.values.type}
                        onChange={formik.handleChange}
                        errorMessage={formik.touched.type && formik.errors.type ? formik.errors.type : ''}
                        // @ts-ignore
                        options={types}
                    />
                    <TextField
                        fullWidth={true}
                        id="email"
                        name="email"
                        label="email-adresse"
                        placeholderText="example@gmail.com"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        errorMessage={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                    />
                    <TextField
                        fullWidth={true}
                        id="title"
                        name="title"
                        label="titel"
                        placeholderText="e.g. DFP"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        errorMessage={formik.touched.title && formik.errors.title ? formik.errors.title : ''}
                    />
                    <TextField
                        fullWidth={true}
                        multiline={true}
                        minRows={3}
                        maxRows={3}
                        id="body"
                        name="body"
                        label="nachricht"
                        placeholderText="Gib hier deine Nachricht ein"
                        value={formik.values.body}
                        onChange={formik.handleChange}
                        errorMessage={formik.touched.body && formik.errors.body ? formik.errors.body : ''}
                    />
                    <Button type="submit" fullWidth={true} sx={{mt: '8px'}} disabled={formik.isSubmitting}>Absenden</Button>
                    <Box fontFamily="Inter" textAlign="center" fontSize={14} color="#8E8E8E" position="absolute" top={5}>
                        {message}
                    </Box>
                </Form>
            </Box>
            {/* MAP */}
            <Box width={{xs: '100%', md: '50%'}} height={{xs: '238px', md: 'auto'}}
                 onClick={() => window.open(GOOGLE_MAPS_LINK, '_blank')} sx={{cursor: 'pointer'}}>
                <img width="100%" height="100%" style={{objectFit: 'cover'}} src={GOOGLE_MAPS_STATIC_LINK}
                     alt="location map"/>
            </Box>
        </Box>
    )
};

export default FormWithMapWidget;
