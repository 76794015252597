import React from 'react';
import 'swiper/css';
import ReactDOM from 'react-dom/client';
import App from './App';
import HTMLText from "./components/common/html-text";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <App />
);
