import { Box } from '@mui/material';
import ContactPerson from '../../common/contact-person';
import FormWithMapWidget from './form-with-map-widget';
import { Title, Text, Divider } from './style';

const Kontakt = () => {
    return (
        <Box p="0 20px" sx={{ backgroundColor: '#FFF' }}>
            <Box maxWidth="940px" m="0 auto">
                <Box m={{ xs: '40px 0 0 0', md: '40px 80px' }}>
                    <Title>Kontakt</Title>
                    <Text>
                        Du hast Fragen zum Produkt und weißt nicht weiter? Du möchtest mit uns kooperieren?
                        Dann bist du hier genau richtig. Wir helfen dir gerne weiter.
                    </Text>
                </Box>
                <Divider display={{ xs: 'none', md: 'block' }} />
                <Box m={{ xs: '32px 0 0 0', md: '32px 0 244px 0' }} flexDirection={{ xs: 'column', md: 'row' }} display="flex" justifyContent="space-evenly">
                    <Box borderBottom={{ xs: '1px solid #C4C4C4', md: 'none' }} p={{ xs: '20px 0', md: 0 }} display={{ xs: 'flex', md: 'block' }} justifyContent="center">
                        <ContactPerson imageUrl="/ad.jpg" name="Anna Dimitrijevic" position="HEAD OF SALES" email="a.dimitrijevic@meducately.com" />
                    </Box>
                    <Box p={{ xs: '20px 0 50px 0', md: 0 }}  display={{ xs: 'flex', md: 'block' }} justifyContent="center">
                        <ContactPerson imageUrl="/fh.jpg" name="Dr. Fritz Höllerer" position="HEAD OF COOPERATIONS" email="f.hoellerer@meducately.com" />
                    </Box>
                </Box>
            </Box>
            <Box sx={{ backgroundColor: '#FCFCFC' }} height={{ xs: 'auto', md: '418px' }} p={{ xs: '60px 0 0 0', md: '0 0 20px 0' }} m="0 -20px">
                <Box maxWidth="940px" m={{ xs: '0', md: '0 auto' }} position="relative" top={{ xs: 0, md: '-160px' }}>
                    <Box display="flex" justifyContent="center">
                        <FormWithMapWidget />
                    </Box>
                    <Box textAlign="center" p={{ xs: "60px 20px 50px 20px", md: "28px 0 38px 0" }}>
                        <Text>meducately ist eine eingetragene Marke der Hellomint GmbH</Text>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
};

export default Kontakt;
