import React from 'react';
import {Box, CardMedia} from "@mui/material";
import {Separator} from "../partnership/style";
import ContactPerson from "../../common/contact-person";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

const UberUns = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box sx={{
            width: '100%',
            backgroundColor: '#FFFFFF'
        }}>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: {
                    lg: '1230px',
                    xs: '95%'
                },
                padding: '0 15px',
                margin: '0 auto',
                fontFamily: 'Inter'
            }}>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: {
                        sm: 'initial',
                        xs: 'flex-start'
                    },
                    padding: {
                        sm: '56px 0 40px 0',
                        xs: '56px 20px 40px 20px'
                    }
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        textTransform: 'uppercase',
                        color: '#F05B4D',
                        fontWeight: 500,
                        fontSize: '15px',
                        lineHeight: '18px',
                        textAlign: 'center'
                    }}>Was genau ist meducately</Box>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        textTransform: {
                            md: 'uppercase',
                            xs: 'capitalize',
                        },
                        color: '#1D1D1D',
                        fontWeight: 800,
                        fontSize: {
                            md: '40px',
                            xs: '28px',
                        },
                        lineHeight: {
                            md: '48px',
                            xs: '34px',
                        },
                        maxWidth: {
                            sm: 'initial',
                            xs: '335px'
                        }
                    }}>DIE WEITERBILDUNGS-PLATTFORM FÜR ÄRZTE </Box>
                </Box>

                <Separator/>

                {/* @ts-ignore */}
                <Box display="flex" justifyContent={{xs: 'center', lg: 'space-between'}} alignItems={{xs: 'flex-start', sm: 'center'}}
                     gap={{xs: 6, sm: 11}}
                     flexDirection={{xs: 'column', sm: 'row'}}
                    // width={{ xs: '90%', md: '80%', lg: '1200px' }}
                    mb={{xs: '24px'}}
                     p={{xs: '32px 0 0 20px', sm: '40px 70px 56px 70px', lg: '40px 130px'}} flexWrap={{xs: 'wrap'}}>
                    <Box width="235px">
                        <Box component="span"
                             fontSize="40px"
                             lineHeight="48px"
                             fontFamily="Inter"
                             fontWeight={800}>
                            4000+
                            {/* @ts-ignore */}
                            <Box component="div" height="10px" width="180px" backgroundColor="#F05B4D"
                                 marginTop="-12px"/>
                        </Box>
                        <Box component="p"
                             margin={0}
                             fontFamily="Inter"
                             fontSize="15px"
                             color="#676767"
                             lineHeight="18px"
                             mt="15px">Unsere Videothek besteht aus mehr als 4000 Videos, die aus unterschiedlichen
                            Quellen zusammengestellt wurden.</Box>
                    </Box>

                    <Box width="235px">
                        <Box component="span"
                             fontSize="40px"
                             lineHeight="48px"
                             fontFamily="Inter"
                             fontWeight={800}>
                            80+
                            {/* @ts-ignore */}
                            <Box component="div" height="10px" width="180px" backgroundColor="#F05B4D"
                                 marginTop="-12px"/>
                        </Box>
                        <Box component="p"
                             margin={0}
                             fontFamily="Inter"
                             fontSize="15px"
                             color="#676767"
                             lineHeight="18px"
                             mt="15px">DFP Punkte kannst du direkt über uns sammeln. Oder man meldet sich direkt und einfach für kommende Webinare an.</Box>
                    </Box>

                    <Box width="235px">
                        <Box component="span"
                             fontSize="40px"
                             lineHeight="48px"
                             fontFamily="Inter"
                             fontWeight={800}>
                            200+
                            {/* @ts-ignore */}
                            <Box component="div" height="10px" width="180px" backgroundColor="#F05B4D"
                                 marginTop="-12px"/>
                        </Box>
                        <Box component="p"
                             margin={0}
                             fontFamily="Inter"
                             fontSize="15px"
                             color="#676767"
                             lineHeight="18px"
                             mt="15px">hand-selektierte Kanäle, die themenrelevant genau auf dich und deine Fachrichtung zugeschnitten sind.</Box>
                    </Box>
                </Box>

                <Separator/>

                {!isMobile
                    ? <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '5%',
                        padding: {
                            lg: '55px 130px',
                            xs: '55px 20px'
                        },
                        backgroundColor: '#FCFCFC'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: '40%'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                fontWeight: 500,
                                fontSize: '15px',
                                lineHeight: '18px',
                                textTransform: 'uppercase',
                                color: '#F05B4D',
                                marginBottom: '5px'
                            }}>Über uns</Box>
                            <Box sx={{
                                display: 'flex',
                                fontWeight: 700,
                                fontSize: '24px',
                                lineHeight: '32px',
                                color: '#0E0E0E',
                                marginBottom: '24px'
                            }}>„Unser Ziel ist es Fortbildungen sichtbar zu machen und einen Ort zu schaffen, wo
                                ÄrztInnen
                                sich genau über diese Inhalte zentral informieren können. “</Box>
                            <Box sx={{
                                display: 'flex',
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#404040'
                            }}>Meducately greift all diese Problemfelder auf und vereint Video-Fortbildung jeglicher Art
                                auf
                                einer Plattform. Alle Videos werden geprüft und im Anschluss Themen und Fachrichtungen
                                zugeordnet. So erhält jeder Arzt und jede Ärztin, die für sie relevanten Informationen
                                zuerst präsentiert. Das spart Zeit bei der Suche und reduziert Frustration bei der
                                Auswahl.
                                Mit der Suchfunktion kann der User auf mehr als 3.000 Videos zugreifen und diese
                                kostenlos
                                ansehen. Der Unterpunkt Events zeigt eine Auswahl an virtuellen Veranstaltungen an denen
                                User teilnehmen können. Mit einem Klick und aus freien Stücken heraus. </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: '55%'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '40px'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 800,
                                    fontSize: '40px',
                                    lineHeight: '48px',
                                    color: '#F05B4D'
                                }}>
                                    1
                                </Box>
                                <Box sx={{
                                    paddingLeft: '27px',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    color: '#404040',
                                }}>Medizinische Inhalte sind dezentral auf vielen Plattformen verteilt; Betrieben von
                                    Gesellschaften, Agenturen, Verlagen oder der Pharmazeutischen Industrie.</Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '40px'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 800,
                                    fontSize: '40px',
                                    lineHeight: '48px',
                                    color: '#F05B4D'
                                }}>
                                    2
                                </Box>
                                <Box sx={{
                                    paddingLeft: '27px',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    color: '#404040',
                                }}>Ärzte haben eine immense Auswahlmöglichkeit an Plattformen, bevorzugen aber lieber 1
                                    Anlaufstelle für Informationen und nicht 10 oder mehr.</Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '40px'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 800,
                                    fontSize: '40px',
                                    lineHeight: '48px',
                                    color: '#F05B4D'
                                }}>
                                    3
                                </Box>
                                <Box sx={{
                                    paddingLeft: '27px',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    color: '#404040',
                                }}>Fortbildungen werden zumeist nur dann abgehalten oder produziert, wenn es finanzielle
                                    Unterstützung von Sponsoren gibt. So findet man für metastasiertes Bronchialkarzinom
                                    unzählige Fortbildungen, für Nagelpilz oder Lungenentzündung umso weniger. </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '40px'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 800,
                                    fontSize: '40px',
                                    lineHeight: '48px',
                                    color: '#F05B4D'
                                }}>
                                    4
                                </Box>
                                <Box sx={{
                                    paddingLeft: '27px',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    color: '#404040',
                                }}>Praxisrelevante Themen sind für manche Fachrichtungen unterrepräsentiert und sollten
                                    stärker unterstützt werden.</Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 800,
                                    fontSize: '40px',
                                    lineHeight: '48px',
                                    color: '#F05B4D'
                                }}>
                                    5
                                </Box>
                                <Box sx={{
                                    paddingLeft: '27px',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    color: '#404040',
                                }}>Webinareinladungen werden von unterschiedlichen Stellen und Absendern ausgeschickt
                                    und
                                    gehen in einer Vielzahl an E-Mails unter. </Box>
                            </Box>
                        </Box>
                    </Box>
                    : <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: {
                            lg: '55px 130px',
                            xs: '55px 20px'
                        },
                        backgroundColor: '#FCFCFC'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '32px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                fontWeight: 500,
                                fontSize: '15px',
                                lineHeight: '18px',
                                textTransform: 'uppercase',
                                color: '#F05B4D',
                                marginBottom: '5px'
                            }}>Über uns</Box>
                            <Box sx={{
                                display: 'flex',
                                fontWeight: 700,
                                fontSize: '24px',
                                lineHeight: '32px',
                                color: '#0E0E0E',
                                marginBottom: '24px'
                            }}>„Unser Ziel ist es Fortbildungen sichtbar zu machen und einen Ort zu schaffen, wo
                                ÄrztInnen
                                sich genau über diese Inhalte zentral informieren können. “</Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '32px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '40px'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 800,
                                    fontSize: '40px',
                                    lineHeight: '48px',
                                    color: '#F05B4D'
                                }}>
                                    1
                                </Box>
                                <Box sx={{
                                    paddingLeft: '27px',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    color: '#404040',
                                }}>Medizinische Inhalte sind dezentral auf vielen Plattformen verteilt; Betrieben von
                                    Gesellschaften, Agenturen, Verlagen oder der Pharmazeutischen Industrie.</Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '40px'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 800,
                                    fontSize: '40px',
                                    lineHeight: '48px',
                                    color: '#F05B4D'
                                }}>
                                    2
                                </Box>
                                <Box sx={{
                                    paddingLeft: '27px',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    color: '#404040',
                                }}>Ärzte haben eine immense Auswahlmöglichkeit an Plattformen, bevorzugen aber lieber 1
                                    Anlaufstelle für Informationen und nicht 10 oder mehr.</Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '40px'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 800,
                                    fontSize: '40px',
                                    lineHeight: '48px',
                                    color: '#F05B4D'
                                }}>
                                    3
                                </Box>
                                <Box sx={{
                                    paddingLeft: '27px',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    color: '#404040',
                                }}>Fortbildungen werden zumeist nur dann abgehalten oder produziert, wenn es finanzielle
                                    Unterstützung von Sponsoren gibt. So findet man für metastasiertes Bronchialkarzinom
                                    unzählige Fortbildungen, für Nagelpilz oder Lungenentzündung umso weniger. </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '40px'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 800,
                                    fontSize: '40px',
                                    lineHeight: '48px',
                                    color: '#F05B4D'
                                }}>
                                    4
                                </Box>
                                <Box sx={{
                                    paddingLeft: '27px',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    color: '#404040',
                                }}>Praxisrelevante Themen sind für manche Fachrichtungen unterrepräsentiert und sollten
                                    stärker unterstützt werden.</Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 800,
                                    fontSize: '40px',
                                    lineHeight: '48px',
                                    color: '#F05B4D'
                                }}>
                                    5
                                </Box>
                                <Box sx={{
                                    paddingLeft: '27px',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    color: '#404040',
                                }}>Webinareinladungen werden von unterschiedlichen Stellen und Absendern ausgeschickt
                                    und
                                    gehen in einer Vielzahl an E-Mails unter. </Box>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#404040'
                            }}>Meducately greift all diese Problemfelder auf und vereint Video-Fortbildung jeglicher Art
                                auf
                                einer Plattform. Alle Videos werden geprüft und im Anschluss Themen und Fachrichtungen
                                zugeordnet. So erhält jeder Arzt und jede Ärztin, die für sie relevanten Informationen
                                zuerst präsentiert. Das spart Zeit bei der Suche und reduziert Frustration bei der
                                Auswahl.
                                Mit der Suchfunktion kann der User auf mehr als 3.000 Videos zugreifen und diese
                                kostenlos
                                ansehen. Der Unterpunkt Events zeigt eine Auswahl an virtuellen Veranstaltungen an denen
                                User teilnehmen können. Mit einem Klick und aus freien Stücken heraus. </Box>
                        </Box>
                    </Box>
                }

                <Separator/>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: {
                        md: '55px 0 65px 0',
                        xs: '40px 0 12px 0'
                    }
                }}>
                    <Box sx={{
                        display: 'flex',
                        textTransform: 'uppercase',
                        textAlign: {
                            xs: 'center'
                        },
                        color: '#F05B4D',
                        fontWeight: 500,
                        fontSize: '15px',
                        lineHeight: '18px'
                    }}>Meducately team</Box>

                    <Box sx={{
                        display: 'flex',
                        textTransform: {
                            md: 'uppercase',
                            xs: 'capitalize',
                        },
                        textAlign: {
                            xs: 'center'
                        },
                        color: '#0E0E0E',
                        fontWeight: 800,
                        fontSize: {
                            xs: '28px',
                        },
                        lineHeight: {
                            xs: '34px',
                        }
                    }}>ZU IHRER VERFÜGUNG</Box>
                </Box>

                <Box m={{xs: '32px 0 0 0', md: '0 0 60px 0'}} flexDirection={{xs: 'column', md: 'row'}} display="flex"
                     justifyContent="space-evenly">
                    <Box borderBottom={{xs: '1px solid rgba(196, 196, 196, 0.4)', md: 'none'}} p={{xs: '20px 0', md: 0}}
                         display={{xs: 'flex', md: 'block'}} justifyContent="center">
                        <ContactPerson imageUrl="/ad.jpg" name="Anna Dimitrijevic"
                                       position="HEAD OF SALES" email="a.dimitrijevic@meducately.com"/>
                    </Box>
                    <Box p={{xs: '20px 0 50px 0', md: 0}} display={{xs: 'flex', md: 'block'}} justifyContent="center">
                        <ContactPerson imageUrl="/fh.jpg" name="Dr. Fritz Höllerer"
                                       position="HEAD OF COOPERATIONS" email="f.hoellerer@meducately.com"/>
                    </Box>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: {
                        md: '48px',
                        xs: '32px'
                    }
                }}>
                    <Box sx={{
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '24px',
                        textAlign: 'center',
                        color: '#404040',
                        maxWidth: {
                            md: '600px',
                            xs: '335px'
                        },
                        marginBottom: {
                            md: '8px',
                            xs: '32px'
                        }
                    }}>Wir hoffen einen kleinen Beitrag zu leisten, um medizinische Fortbildung sichtbarer zu machen und
                        freuen uns auf viele begeisterte User.</Box>

                    <Box sx={{
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: {
                            md: '19px',
                            xs: '24px'
                        },
                        textAlign: 'center',
                        fontStyle: 'italic',
                        color: '#404040',
                        marginBottom: '32px'
                    }}>
                        Euer meducately Team
                    </Box>

                    <Box sx={{
                        fontWeight: 400,
                        fontSize: '15px',
                        maxWidth: {
                            md: 'initial',
                            xs: '335px'
                        },
                        lineHeight: {
                            md: '18px',
                            xs: '24px'
                        },
                        textAlign: 'center',
                        fontStyle: 'italic',
                        color: '#676767'
                    }}>
                        Hinweis: meducately ist eine Marke der Hellomint GmbH und wird von dieser betrieben.
                    </Box>
                </Box>

                <Separator/>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: {
                        md: '55px 0 65px 0',
                        xs: '40px 0 35px 0'
                    }
                }}>
                    <Box sx={{
                        display: 'flex',
                        textTransform: 'uppercase',
                        textAlign: {
                            xs: 'center'
                        },
                        color: '#F05B4D',
                        fontWeight: 500,
                        fontSize: '15px',
                        lineHeight: '18px'
                    }}>EINIGE UNSERER QUELLEN</Box>

                    <Box sx={{
                        display: 'flex',
                        textTransform: 'uppercase',
                        textAlign: 'center',
                        color: '#0E0E0E',
                        fontWeight: 800,
                        fontSize: '28px',
                        lineHeight: '34px',
                        maxWidth: {
                            md: 'initial',
                            xs: '230px'
                        }
                    }}>LERNE VON DEN BESTEN</Box>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: '56px',
                    padding: {
                        md: '0 130px'
                    }
                }}>
                    {!isMobile
                        ? <img style={{maxWidth: '100%'}} src='/logos-desktop-new.png' alt="profile image"/>
                        : <img style={{maxWidth: '100%'}} src='/logos-mobile-new.png' alt="profile image"/>
                    }
                </Box>


            </Box>

        </Box>
    )
}

export default UberUns;
